<template>
  <div>
    <gmap-map
      :zoom="16"
      :center="center"
      style="width: 100%; height: 600px"
    >
      <gmap-marker
        v-for="(m, index) in locationMarkers"
        :key="index"
        :position="m.position"
        :label="{
          text: m.vreme,
          color: 'white',
          fontWeight: 'bold',
          fontSize: '10px',
        }"
        @click="center = m.position"
      />
    </gmap-map>

  </div>
</template>

<script>
export default {
  name: 'AddGoogleMap',
  props: {
    casId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      label: {
        text: 'label',
        color: 'black',
        fontWeight: 'bold',
        fontSize: '5px',
      },
      center: {
        lat: 0,
        lng: 0,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      path: [
        { lat: 45.6723496, lng: 18.9848363 },
        { lat: 45.67232, lng: 18.9848397 },
      ],
    }
  },

  mounted() {
    this.pokupiListu()
    this.locateGeoLocation()
  },

  methods: {
    async pokupiListu() {
      const response = await this.$http.get(`/instruktori/cas-prakticne-obuke-prolazne-tacke/${this.casId}`)
      response.data.forEach(element => this.locationMarkers.push({
        position: {
          lat: element.position.lat,
          lng: element.position.lng,
        },
        vreme: element.vreme,
      }))
      this.center = this.locationMarkers[0].position
    },
    initMarker(loc) {
      this.existingPlace = loc
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        }
        this.locationMarkers.push({ position: marker })
        this.locPlaces.push(this.existingPlace)
        this.center = marker
        this.existingPlace = null
      }
    },
    locateGeoLocation() {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        }
      })
    },
  },
}
</script>
